<template>
  <div>
    <van-row align="center" @click="toGuideInfo(guider.id)">
      <van-col span="5">
        <van-image
          style="margin-top: 10px"
          round
          :src="guider.avatar"
          fit="cover"
          width="60"
          height="60"
        />
      </van-col>
      <van-col span="15" style="font-size: 12px;padding-left:10px">
        <van-row style="font-size: 18px; font-weight: 600; margin-top: 10px">
          {{guider.name}}
          <van-tag round type="primary">{{guider.level}}导游</van-tag>
        </van-row>
        <van-row style="margin-top: 5px; color: grey">
          电话：{{guider.mobile}}
        </van-row>
        <van-row style="margin-top: 5px; color: grey"> 
          {{tour != undefined && tour.list_company != undefined? tour.list_company.name : guider.travel_company_name}} 
        </van-row>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  props: {
    guider: {
      type: Object,
      default: () => {}
    },
    tour: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    toGuideInfo(id) {
      this.$router.push(`/guider/info/${id}`)
    }
  }
};
</script>

<style>
</style>