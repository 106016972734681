<template>
  <div style="min-height:100vh;">
    <titleLink title="投诉详情" :onClick="onClickBack"></titleLink>
    <div style="padding:.2rem .2rem 2rem .2rem;margin-top:.2rem" class="view-body">
      <card style="margin-top:.5rem;padding:.4rem;line-height:.3rem">
        <template #content>
          <van-row>
            <van-col span="24" class="list-title-text-normal">
              {{currentFeedback.feedback_content == ''?'暂无评价':currentFeedback.feedback_content}}
            </van-col>
            <van-col class="list-info-text margin-top-10" span="8">
              <div style="padding:.1rem 0 .1rem 0" class="feedback_comment_tag">
                投诉对象
              </div>
            </van-col>
            <van-col span="16" class="text-right margin-top-10">
              <span class="feedback_comment_tag">
                {{currentFeedback.feedback_object.object}}&nbsp;-&nbsp;{{currentFeedback.feedback_object.name}}
              </span>
            </van-col>
            <van-col span="24">
              <van-row type="flex">
                <van-tag v-for="(tag, idx) in currentFeedback.tags" :key="'tag:' + idx" round type="primary"
                  style="margin-right:.1rem">
                  {{tag}}
                </van-tag>
              </van-row>
            </van-col>
            <van-col class="feedback_comment_tag" span="12" style="height:.2rem;line-height:.2rem;padding-top:0">
              提交时间
            </van-col>
            <van-col class="feedback_comment_tag" span="12" style="text-align:right;height:.2rem;">
              {{currentFeedback.updated_at}}
            </van-col>
            <van-col span="24">
              <span class="feedback_comment_tag">图片</span>
            </van-col>
            <van-col span="8" v-show="currentFeedback.images[0] != ''" @click="showPreview(currentFeedback.images[0])">
              <van-image :src="currentFeedback.images[0]" fit="contain"  
                style="margin-top:.2rem"/>
            </van-col>
            <van-col span="24" class="text-right">
              <van-tag round :type="currentFeedback.approve_status == null? 'warning'
              :currentFeedback.approve_status == 1? 'primary': 'danger'"
              :color="currentFeedback.approve_status == 1?'rgba(64, 169, 255, 0.16)'
                : currentFeedback.approve_status == 2? '#f9f0f5': '#f5f5f5'"
              :text-color="currentFeedback.approve_status == 1?'#40a9ff'
                :currentFeedback.approve_status == 2? '#D52B4D' : '#666666'">
                {{currentFeedback.approve_status == null? '未处理': currentFeedback.approve_status_text}}
              </van-tag>
            </van-col>
          </van-row>
          <van-divider content-position="left">回复</van-divider>
          <van-row v-for="(item, idx) in currentFeedback.replies" :key="idx" style="margin-top:20px;" 
            class="comment-bg padding-10">
            <van-col class="feedback_title_name" span="24" style="height:.3rem;">
              {{item.content}}
            </van-col>
            <van-col class="feedback_comment_tag margin-top-10" span="12" style="height:.2rem;">
              {{item.user_name}}
            </van-col>
            <van-col span="12" style="height:.2rem;" class="margin-top-10 text-right feedback_comment_tag">
              {{item.reply_at}}
            </van-col>
          </van-row>
          <van-row v-if="currentFeedback.replies == undefined || currentFeedback.replies.length == 0">
            <van-empty description="暂无回复"/>
          </van-row>
        </template>
      </card>
    </div>
    
  </div>
</template>

<script>
import card from "@/views/components/Card"
import {mapGetters} from "vuex"
import tourInfoTop from "@/views/components/TourInfoTop"
import guideInfo from "@/views/components/GuideInfo"
import titleLink from "@/views/components/TitleLink"
import { ImagePreview } from "vant";
export default {
  components: {
    card,
    tourInfoTop,
    guideInfo,
    titleLink
  },
  computed: {
    ...mapGetters('feedback', ['currentFeedback']),
    ...mapGetters('wode', {
      user: 'getPersonInfo'
    })
  },
  created() {
    const id = this.$route.params.id
    this.$store.dispatch('wode/getPersonInfo')
    this.$store.dispatch('feedback/get_feedback_by_id', id)
  },
  methods: {
    onClickBack() {
      this.$router.go(-1)
    },
    toCompanyInfo(id) {
      this.$router.push(`/guider/companyInfo/${id}`)
    },
    showPreview(src) {
      ImagePreview(src)
    }
  }
}
</script>

<style scoped>
.feedback_title_name {
  font-size: 16px;
}
.feedback_comment_tag {
  font-size: 14px;
  color: #666666;
}
</style>