<template>
  <div class="card-item">
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: "Card",
};
</script>

<style>
.card-item {
  background: #ffffff;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  padding: 0.25rem;
  position: relative;
  margin-bottom: 10px;
}
</style>
